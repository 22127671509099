import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "nfteasy",
      "style": {
        "position": "relative"
      }
    }}>{`NFTeasy`}<a parentName="h1" {...{
        "href": "#nfteasy",
        "aria-label": "nfteasy permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Everybody and their pet penguin has an NFT project these days. This is one of the features of open and permissionless technology: it’s wonderful until everyone starts using it, at which point it immediately feels overwhelming. But don’t grieve, dear heart, nor fear the seeming: here is waking in the dreaming. `}</p>
    <p>{`We’ll run through the tools you need to start writing your own contracts and consider whether it’s even interesting at all to create non-fungible digital items. This will not immediately make you into a shadowy super coder, but it will give you the skills and knowledge to understand, analyse and navigate the less fungible and supposedly more permanent parts of web3.`}</p>
    <p>{`There will be four lessons, and each will last for ~2hours, as the intention is to have a lot of space for shared explorations, questions, and a calm approach to learning about a new world.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/build/digital-tree.jpg",
        "alt": "The Would From The Trie"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      